import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { Layout } from '../components/common'

const NotFoundPage = ({ data }) => {
    const siteSettings = data.allSanitySiteSettings.edges[0].node

    return (
        <Layout>
            <div className="container">
                <article className="content" style={{ textAlign: `center` }}>
                    <h1 className="content-title">{siteSettings.errorPageTitle}</h1>
                    <section className="content-body">
                    {siteSettings.errorPageTextPrefix}<Link to="/">{siteSettings.errorPageGoBackHomeText}</Link>{siteSettings.errorPageTextPostfix}
                    </section>
                </article>
            </div>
        </Layout>
    )
}

export default NotFoundPage

export const pageQuery = graphql`
  query ErrorPageQuery {
    allSanitySiteSettings {
        edges {
            node {
                errorPageTitle
                errorPageTextPrefix
                errorPageGoBackHomeText
                errorPageTextPostfix
            }
        }
    }
  }
`
